<template>
  <section class="faq-container">
    <div class="pb-6" :class="{'container-md': !isFullWidth}">
      <div class="content" 
    :class="{'mb-6': isPlayfair}">
    <!-- <div class="content" 
    data-aos="fade-up"
    :class="{'mb-6': isPlayfair}"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"> -->
      <h1 class="title mt-5" :class="{'playfair has-text-centered': isPlayfair}">Frequently Asked Questions</h1>
    </div>
    <b-collapse
      :open="isQuestionsOpened"
      class="mb-2"
      animation="slide"
      v-for="(collapse, index) of questions"
      :key="index"
      @open="isOpen = index"
    >
    <!-- <b-collapse
      :open="isQuestionsOpened"
      class="mb-2"
      animation="slide"
      v-for="(collapse, index) of questions"
      :key="index"
      @open="isOpen = index"
      data-aos="fade-up"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    > -->
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title" :class="{'playfair': isPlayfair}">
            {{ collapse.title }}
          </p>
          <a class="card-header-icon" v-show="props.open">
            <img src="../assets/minus.svg" class="icon" />
          </a>
          <a class="card-header-icon" v-show="!props.open">
            <img src="../assets/add.svg" class="icon" />
          </a>
        </div>
      </template>
      <div class="card-content">
        <div>
          <span v-html="collapse.text"> {{ collapse.text }}</span>
        </div>
      </div>
    </b-collapse>
  </div>
  </section>
</template>

<script>
export default {
  props: {
    propQuestions: {
      type: Array,
      default: () =>  [
        {
          title: "What sizes of groups can you handle?",
          text: `We specialize in groups of 8-53 people but can accommodate larger groups with advanced notice.`,
        },
        {
          title:
            "How do you ensure the quality of the guides and services you provide?",
          text: "We work exclusively with licensed, experienced guides who are experts in Portuguese history, culture, and tourism. Additionally, all our service providers are vetted for professionalism and reliability to ensure top-notch experiences for you.",
        },

        {
          title: "Are your services available throughout Portugal?",
          text: "Yes, we operate across all of Portugal. Wherever your group wants to explore, we'll be there to ensure everything runs smoothly and your clients have an amazing experience.",
        },
        {
          title: "What types of vehicles do you use for private transport?",
          text: "We offer a range of vehicles depending on group size and preference, including luxury vans, minibuses, and full-size coaches. All vehicles are modern, comfortable, and equipped with amenities to ensure a pleasant journey.",
        },
        /* {
            title: "Do you provide travel insurance?",
            text: "While we don’t directly offer travel insurance, we can recommend trusted providers who specialize in group travel insurance.",
          }, */
        {
          title:
            "How do I receive updates about the group’s itinerary and status during their trip?",
          text: "We provide real-time updates and are always available via phone or email to ensure you’re informed and confident throughout your clients’ trip.",
        },
        {
          title:
            "I’m planning a group trip. Can Guiders Advisors help?",
          text: "Of course! The more the merrier. Whether you’re organizing a family reunion, honeymoon, bachelorette party or company-wide retreat, we’re happy to help. Just get in touch with us to begin the process.",
        },
        /* {
            title: "Can you accommodate special dietary needs or requests?",
            text: "Absolutely. We work with a variety of restaurants and caterers who can provide meals for all dietary preferences.",
          }, */
        {
          title: "How far in advance should I contact you for a group booking?",
          text: "We recommend reaching out at least 1 month in advance for the best availability and options.",
        },
      ],
    },
    isQuestionsOpened: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isPlayfair: {
      type: Boolean,
      default: true
    }
  },
  head() {
    return {
      title: "Guiders.pt | Frequently Asked Questions",
      link: [
        {
          rel: "canonical",
          href: "https://guiders.pt/frequently-asked-questions",
        },
      ],
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Get answers to your questions on Guiders with our comprehensive FAQ. Find solutions to common inquiries, tips, and expert insights to make your next experience easier for you.",
        },
        {
          hid: "link",
          name: "link",
          content: "https://guiders.pt/frequently-asked-questions",
        },
      ],
    };
  },
  computed: {
    questions() {
      return this.propQuestions.length ? this.propQuestions : this.collapses;
    },
  },
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "What sizes of groups can you handle?",
          text: `We specialize in groups of 8-53 people but can accommodate larger groups with advanced notice.`,
        },
        {
          title:
            "How do you ensure the quality of the guides and services you provide?",
          text: "We work exclusively with licensed, experienced guides who are experts in Portuguese history, culture, and tourism. Additionally, all our service providers are vetted for professionalism and reliability to ensure top-notch experiences for you.",
        },

        {
          title: "Are your services available throughout Portugal?",
          text: "Yes, we operate across all of Portugal. Wherever your group wants to explore, we'll be there to ensure everything runs smoothly and your clients have an amazing experience.",
        },
        {
          title: "What types of vehicles do you use for private transport?",
          text: "We offer a range of vehicles depending on group size and preference, including luxury vans, minibuses, and full-size coaches. All vehicles are modern, comfortable, and equipped with amenities to ensure a pleasant journey.",
        },
        /* {
            title: "Do you provide travel insurance?",
            text: "While we don’t directly offer travel insurance, we can recommend trusted providers who specialize in group travel insurance.",
          }, */
        {
          title:
            "How do I receive updates about the group’s itinerary and status during their trip?",
          text: "We provide real-time updates and are always available via phone or email to ensure you’re informed and confident throughout your clients’ trip.",
        },
        {
          title:
            "I’m planning a group trip. Can Guiders Advisors help?",
          text: "Of course! The more the merrier. Whether you’re organizing a family reunion, honeymoon, bachelorette party or company-wide retreat, we’re happy to help. Just get in touch with us to begin the process.",
        },
        /* {
            title: "Can you accommodate special dietary needs or requests?",
            text: "Absolutely. We work with a variety of restaurants and caterers who can provide meals for all dietary preferences.",
          }, */
        {
          title: "How far in advance should I contact you for a group booking?",
          text: "We recommend reaching out at least 1 month in advance for the best availability and options.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>

.faq-container{
  background-color: #ffff;
}

.icon {
  margin-right: 8px;
}

.card-header {
  box-shadow: none;
  border-radius: 0;
  padding-bottom: 0.5rem;
}

.card-header-title {
  color: #0e3d4d;
  font-size: 1.2rem;
  font-weight: 300;
}

.playfair {
  font-family: "Playfair Display", serif; 
}

.card-content {
  padding: 0 14px 30px 14px;
  font-weight: 300;
  font-size: .9rem;
  color: #0e3d4dab;
}

.collapse {
  border-bottom: 1px solid #0e3d4d;
}

.title {
  color: #0e3d4d;
  font-weight: 500;
  font-size: 1.5rem;
}
</style>
