import { render, staticRenderFns } from "./HpFAQ.vue?vue&type=template&id=737a785b&scoped=true&"
import script from "./HpFAQ.vue?vue&type=script&lang=js&"
export * from "./HpFAQ.vue?vue&type=script&lang=js&"
import style0 from "./HpFAQ.vue?vue&type=style&index=0&id=737a785b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737a785b",
  null
  
)

export default component.exports